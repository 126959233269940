// BEGINNING OF STYLESHEET

$carousel_control_width: 32px;

// Carousel
.carousel {
	width: 100%;
	height: auto;
	margin:2em auto;
	position: relative;
	text-align: center;

	// Inner Carousel
	.carousel-inner {
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;
		@include transition(400ms);

		// Carousel Item
		> .item {
			width: 90%;
			margin: 0 5%;
			position: relative;
			display: none;
			@include transition(400ms);

			// Image within a carousel item
			> img,
			> a > img {
				margin: 0 auto;
				display: block;
			}

			// Make item visible
			&.active,
			&.next,
			&.prev {
				display: block;
			}

			// Active item
			&.active {
				left: 0;

				&.left {left: -100%;}
				&.right {left: 100%;}
			}

			// Next item
			&.next {
				position: absolute;
				left: 100%;

				&.left {
					left:0;
				}
			}

			// Previous item
			&.prev {
				position: absolute;
				left: -100%;

				&.right {
					left: 0;
				}
			}
		}
	}

	// Controls
	.carousel-control {
		position: absolute;
		top: 25%;
		opacity: 0.6;
		display: inline-block;
		padding: 0.5em;
		border-radius: 8px;

		> svg {fill:$fg_color;}

		&:focus,
		&:active {
			@include transition(200ms);
			> svg {fill:black;}
		}

		&:hover {
			@include transition(200ms);
			> svg {fill:$link_color;}
		}

		&.left {
			right: auto;
			left: calc(#{$carousel_control_width} * -1);
			float: left;
		}

		&.right {
			left: auto;
			right: calc(#{$carousel_control_width} * -1);
			float: right;
		}
	}
}

// Caption
.carousel-caption {
	display: block;
	width: 80%;
	margin: 0 10%;
	margin-top: 2em;
	text-transform:uppercase;
	font-size:90%;
	opacity:0.5;
}

@media only screen and (max-width:848px) {
	.carousel {width: 90%;}
}