// alignments
.center {
	display:block;
	position:relative;
	margin-left:auto;
	margin-right:auto;
}

.left {float:left;}
.right {float:right;}

// text alignment
.text-center {text-align:center;}
.text-left {text-align:left;}
.text-right {text-align:right;}