// Header
$header_bg_color: $brand_color;
$header_fg_color: #fff;
$header_height: 80px;
$header_title_font_size: 24px;
$header_subtitle_font_size: 16px;
$header_text_shadow: 0 1px 1px rgba(0,0,0,0.2);


// Header
header {
	max-height:$header_height;
	// background: linear-gradient(darken($header_bg_color, 7%), $header_bg_color);

	background-color: $header_bg_color;
	box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
	padding:32px 0;

	a, span {
		display: inline-block;
		color: $header_fg_color;
		font-family: $monospace;
		vertical-align:middle;
		white-space: wrap;
		overflow: hidden;
		text-overflow: ellipsis;
		// text-shadow: $header_text_shadow;
	}

	span {
		&.title {
			font-size: $header_title_font_size;
			line-height: $header_title_font_size + 4px;
		}

		&.subtitle {
			font-size: $header_subtitle_font_size;
			width: calc(100% - 24px);
			display: inline-block;
			margin-top: 16px;
			opacity: 0.8;
			> a {
				font-size: inherit;
			}
		}
	}


	a {
		&.arrow {
			font-size: $header_title_font_size + 2px;
			text-decoration: none;
			margin-right: 16px;
			color: inherit;
			opacity: 0.6;

			&:hover,
			&:focus {
				color: inherit;
				opacity: 1.0;
				text-decoration: none;
			}

			&:active,
			&:active:hover {
				color: invert($header_fg_color);
				opacity: 0.4;
			}
		}

		&:active {
			color: transparentize($header_fg_color, 0.5);
		}
	}
}