// Page Separator

.separator {
	display:inline-block;
	width: 100%;
	text-align: center;
	font-size:125%;
	color:darken($bg_color, 10%);
	line-height: 32px;
	height: 32px;
	&:after{content:"◆";}
}