// Grid

$griditem_bg_color: $base_color;
$griditem_width: 344px;
$griditem_height: 288px;
$griditem_margin: 24px;
$griditem_padding: 12px;
$griditem_border_radius: 4px;

// Grid Item
.grid-item {
	background-color: $griditem_bg_color;
	border-radius: $griditem_border_radius;
	box-shadow: 0 0 0 1px $border_color;
	display: inline-block;
	height: calc(#{$griditem_height} + 48px);
	margin: $griditem_margin;
	padding: $griditem_padding;
	text-decoration: none;
	width: $griditem_width;
	vertical-align: top;
	@include transition(100ms);

	&:focus,
	&:hover {
		box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3), 0 0 1px 1px transparentize($link_color, 0.8);
		@include transition(100ms);
		span {
			text-decoration: underline;
		}

	}

	&:active,
	&:active:focus {
		background-color: $griditem_bg_color;
		box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.2),inset 0 0 0 1px rgba(0,0,0,0.1), inset 0 0 0 1px transparentize($link_color, 0.8);
		@include transition(100ms);

	}

	img {
		border-radius: $griditem_border_radius;
	}

	span {
		display: inline-block;
		color: lighten($fg_color, 30%);
		font-family: $monospace;
		font-size: 14px;
		font-weight: 500;
		max-width: $griditem_width;
		letter-spacing: 4px;
		text-align: center;
		text-transform: uppercase;
		margin-top: 24px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.grid-spacer {
	margin: 36px auto 35px auto;
	display: none;
}

.row.grid-container {
	padding:3em 0;
	width:calc(#{$row_width} + 96px);
}

// MEDIA QUERIES

@media only screen and (max-width:848px) {
	.row.grid-container {
		width:100%;
		margin:0;
	}
}

@media only screen and (max-width:400px) {
	.row.grid-container {
		padding-bottom: 0;
	}

	.grid-item {
		border-radius: 0;
		box-shadow: none;
		background-color:$bg_color;
		height: $griditem_height;
		width: $griditem_width;
		margin: 0;
		padding: 0;

		&:focus,
		&:hover {
			box-shadow: none;
			background-color:$bg_color; 
		}
		
		span {
			margin-top: 24px;
		}
	}

	.grid-spacer {
		display: inline-block;
	}

}

@media only screen and (max-width:344px) {
	.row.grid-container {
		padding-bottom: 0;
	}

	.grid-item {
		border-radius: 0;
		box-shadow: none;
		background-color:$bg_color;
		width: 100%;
		margin: -1em;
		padding: 0;

		&:focus,
		&:hover {
			box-shadow: none;
			background-color:$bg_color; 
		}
		
		span {
			margin-top: 24px;
		}
	}

	.grid-spacer {
		display: inline-block;
	}

}