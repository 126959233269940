// Fonts

@import url('http://fonts.googleapis.com/css?family=Cantarell:400,700|Ubuntu+Mono:400,700|Ubuntu:700');

@font-face {
	font-family:"Deja Vu Sans Mono";
	src:local("DejaVuSansMono"), local(DejaVuSansMono), url(/fonts/DejaVuSansMono.ttf);
	font-weight:200;
}

@font-face {
	font-family:"Menlo Regular";
	src:local("Menlo Regular"), local(MenloRegular), url(/assets/fonts/Menlo-Regular.ttf);
	font-weight:200;
}

// Cursor Animation
@keyframes cursor {
	0%, 50% {
	opacity: 1; }
	51%, 100% {
	opacity: 0; } }


// Terminal
#terminal {

	span[data-terminal] {
		height: 0;
		display: none;
	}

	.output {
		padding:0;
		margin: 0;
		overflow: hidden;
		.line {
			display: block;
			word-wrap: break-word;
			margin: 0 auto;
			&:last-child::after {
				font-family:"Deja Vu Sans Mono", monospace;
				font-size: 9pt;
				content: "█";
				animation-name: cursor;
				animation-iteration-count: infinite;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}

	// GNOME Stylings
	&.gnome {
		width: 768px;
		max-width: 768px;
		box-shadow:0 5px 10px 0 rgba(0,0,0,0.4), 0 0 0 1px #282C2C;
		margin: 2em auto;
		border-radius: 5px 5px 0 0;
		.headerbar {
			font-family:"Cantarell", sans-serif;
			font-weight: 700;
			font-size: 10pt;
			color: #FFFFFF;
			display: block;
			box-shadow:inset 0 1px 0 0 rgba(255,255,255,0.1), 0 1px 0 0 rgba(0,0,0,0.4);
			text-shadow: 0 -1px rgba(0,0,0,0.4);
			background-image: linear-gradient(#424B4B, #393F3F);
			padding: 0;
			height: 38px;
			text-align: center;
			border-radius: 4.5px 4.5px 0 0;
		}
		.title {
			position: relative;
			line-height: 36px;
			margin-left: 32px;
		}
		.window-control {
			padding: 7px;
			width: 16px;
			height: 16px;
			display: block;
			position: relative;
			float: right;
			top: 4px;
			right:4px;
			border-radius: 3px;
		}
		pre {
			background-color: #292929;
			border-radius:0;
			box-shadow: none;
			color: #FFFFFF;
			font-family:"Deja Vu Sans Mono", monospace;
			font-size: 10pt;
			margin: 0;
			max-width: 768px;
			min-height: 400px;
			padding: 1px;
			text-shadow: none;
		}

		.output {
			margin-top: -63px;
		}
	}

	// macOS Stylings
	&.macos {
		width: 768px;
		max-width: 768px;
		box-shadow:0 20px 40px 0 rgba(0,0,0,0.4), 0 0 0 1px rgba(0,0,0,0.1);
		margin: 2em auto;
		border-radius: 5px;
		.headerbar {
			color: #4C4C4C;
			font-family:"Helvetica", 'Roboto', sans-serif;
			font-size: 10pt;
			font-weight: 400;
			background-image: linear-gradient(#EBEBEB, #D3D3D3);
			border-radius: 4.5px 4.5px 0 0;
			box-shadow:inset 0 1px 0 0 rgba(255,255,255,0.1), inset 0 -1px 0 0 rgba(0,0,0,0.1);
			height: 24px;
			padding: 0;
			text-align: center;
		}
		.title {
			line-height: 25px;
			margin-left: -48px;
		}
		.window-controls {
			display: block;
			position: relative;
			float: left;
			top: 2px;
			left: 0;
			> img {
				padding: 2px;
				width: 16px;
				height: 16px;
				display: block;
				position: relative;
				float: left;
				top: 0;
				left: 4px;
			}
		}
		pre {
		background-color: #fff;
		border-radius: 0 0 4.5px 4.5px;
		box-shadow: none;
		font-family:"Menlo Regular", monospace;
		color: #000;
		font-size: 10pt;
		margin: 0;
		max-width: 768px;
		min-height: 400px;
		padding: 4px 5px;
		}

		b {color: #5c9fcf}
		
		.output {
			margin-top: -50px;
		}
	}

	// Ubuntu Stylings
	&.ubuntu {
	width: 768px;
	max-width: 768px;
	box-shadow:0 5px 10px 0 rgba(0,0,0,0.4), 0 0 0 1px #3C3B37;
	margin: 2em auto;
	border-radius: 4px 4px 0 0;
		.headerbar {
		box-shadow:inset 0 1px 0 0 rgba(255,255,255,0.1);
		background-image: linear-gradient(#4D4C47,#3C3B37);
		padding: 0;
		height: 28px;
		border-radius: 3.5px 3.5px 0 0;
		}
		.title {
		display: block;
		position: relative;
		color: #DFDBD2;
		font-family:"Ubuntu", sans-serif;
		font-weight: 700;
		font-size: 11pt;
		line-height: 28px;
		margin-left: 72px;
		text-shadow: 0 1px rgba(0,0,0,0.1);
		text-align: left;
		}
		.window-controls {
		padding: 0;
		display: block;
		position: relative;
		top: 4px;
		left: 6px;
		border-radius: 10px;
		background-image: linear-gradient(to bottom, #444440, #454540);
			> img {
			padding: 0;
			width: 19px;
			height: 19px;
			display: block;
			position: relative;
			float: left;
			left: 0;
			}
		}
		pre {
		font-family:"Ubuntu Mono", monospace;
		font-size: 12pt;
		border-radius: 0 0 4.5px 4.5px;
		min-height: 400px;
		padding: 1px;
		margin: 0;
		border-radius:0;
		box-shadow: none;
		max-width: 768px;
		background-color: #2F0A24;
		color: #FFFFFF;
		}

		.output {
			margin-top: -56px;
		}
	}
}	