// Switches

// switch colors
$switch_active_bg_color: $link_color;
$switch_active_fg_color: #fff;
$switch_bg_color: darken($bg_color, 10%);
$switch_fg_color: lighten($fg_color, 20%);


// changing width and height will scale all aspects of the switch
$switch_width: 64px;
$switch_height: 24px;

$switch_nolabel_width: $switch_width - 20px;
$switch_handle_size: $switch_height - 2px;
$switch_border_radius: $switch_height * 0.5;


$switch_margin: 6px;
$switch_font_size: $switch_height * 0.5;


%switch_font {
	font-weight: 700;
	font-size: $switch_font_size;
	line-height: $switch_height + 1px;
	text-transform: uppercase;
}

// Switch
.switch {
	background-color: transparent;
	border-radius: $switch_border_radius;
	cursor: pointer;
	display: inline-block;
	height: $switch_height;
	margin: $switch_margin;
	padding: 3px;
	position: relative;
	vertical-align: top;
	width: $switch_width;
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	// No Label
	&.no-label {
		width: $switch_nolabel_width;
		> .switch-input:checked ~ .switch-handle {left: $switch_nolabel_width - ($switch_handle_size - 2px);}
		> .switch-trough {font-size: 0;}
	}

	// the styled checkbox
	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;

		&:focus ~ .switch-handle {
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4), 0 0 2px 0 $switch_active_bg_color;
		}

		// Checked
		&:checked ~ .switch-trough {
			background: $switch_active_bg_color;

			&:before {opacity: 0;}
			&:after {opacity: 1;}
		}

		&:checked ~ .switch-handle {
			left: $switch_width - ($switch_handle_size - 2px);
		}
	}

	// Trough
	.switch-trough {
		@extend %switch_font;
		background: $switch_bg_color;
		border-radius: inherit;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
		display: block;
		height: inherit;
		position: relative;
		-webkit-transition: 0.15s ease-out;
		-moz-transition: 0.15s ease-out;
		-o-transition: 0.15s ease-out;
		transition: 0.15s ease-out;
		-webkit-transition-property: opacity background;
		-moz-transition-property: opacity background;
		-o-transition-property: opacity background;
		transition-property: opacity background;

		&:before,
		&:after {
			position: absolute;
			top: 50%;
			margin-top: $switch_margin * -1;
			line-height: 1;
			-webkit-transition: inherit;
			-moz-transition: inherit;
			-o-transition: inherit;
			transition: inherit;
		}

		// "off" text
		&:before {
			content: attr(data-off);
			right: $switch_border_radius - 2px;
			color: $switch_fg_color;
			text-shadow: 0 1px rgba(255, 255, 255, 0.5);
		}

		// "on" text
		&:after {
			content: attr(data-on);
			left: $switch_border_radius - 2px;
			color: $switch_active_fg_color;
			text-shadow: 0 1px rgba(0, 0, 0, 0.2);
			opacity: 0;
		}
	}

	// Handle
	.switch-handle {
		position: absolute;
		padding:0;
		top: 4px;
		left: 4px;
		width: $switch_handle_size;
		height: $switch_handle_size;
		background: $base_color;
		border-radius: 50%;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
		-webkit-transition: left 0.15s ease-out;
		-moz-transition: left 0.15s ease-out;
		-o-transition: left 0.15s ease-out;
		transition: left 0.15s ease-out;
	}
}

// when switches are grouped
ul.switch-group {
	margin:0;
	padding:0;
	list-style:none;
	border:1px solid $border_color;
	border-radius: 3px;
	background-color: $base_color;
	>li {
		width: calc(100% - 12px);
		display: inline-block;
		padding:0 6px;
		border-bottom:1px solid $border_color;
		&:last-child {border:none}
		&:before{content:none}
		> label {
			display: inline-block;
			line-height: $switch_height + 12px;
			vertical-align: middle;
		}
		> .switch-label {
			padding:0 6px;
		}
		> .switch {
			float: right;
		}
	}
}

// label
label.switch-label {
	font-family:$monospace;
	color: lighten($fg_color, 20%);
	font-size: $font_size - 1px;
}