// Cards

$card_bg_color:$base_color;
$card_fg_color:$fg_color;
$card_corner_radius: 5px;
$card_font_size:18px;
$card_height:72px;
$card_width:23%;

$paypal_color:#0070ba;

// Card container
.cards {
	display: inline-block;
	margin: 12px auto;
	width: 100%;
	text-align:center;

	// Card
	a.card {
		background-color: $card_bg_color;
		border-radius: $card_corner_radius;
		color: $card_fg_color;
		display: inline-block;
		font-family:$monospace;
		font-size: $card_font_size;
		font-weight: bold;
		margin: 4px 0.5%;
		padding: calc(#{$card_height} * 0.5) 0;
		text-align: center;
		text-decoration:none;
		text-shadow:none;
		width:$card_width;
		box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2),
					0 0 0 1px rgba(0,0,0,0.15);

		span {
			width: 100%;
			height: 100%;
			text-align: center;
		}

		> svg {
			display: block;
			margin: 0 auto;
			margin-top: 12px;
			fill: $paypal_color;
			> g {fill: $paypal_color;}
		}

		&:active,
		&:active:hover{
			box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.2),
						inset 0 0 0 1px rgba(0,0,0,0.15);
		}

		&:focus {
			box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2),
						inset 0 0 0 1px $link_color;
		}

		&:hover {
			box-shadow: 0 1px 2px 0 $link_color,
						0 0 0 1px $link_color;
		}
	}
}

@media only screen and (max-width: 1000px) {
	.cards > a.card {
		width: calc(#{$card_height} + 96px);
	}
}

@media only screen and (max-width: 400px) {
	.cards > a.card {
		width: calc(#{$card_height} + 48px);
	}
}