// Common values
$legacy_browser_support: false !default;

// Font Family
$font_family: "source-sans-pro", sans-serif;
$monospace: "overpass-mono", monospace;

// Text
$font_size: 16px;
$font_weight: 400;

// Main colors
$bg_color: #f5f6f7;
$fg_color: #333;
$base_color: #fff;
$brand_color: #00abcd;

// primary colors
$green_color: #00ff99;
$pink_color: #ee0066;
$blue_color:#6655ff;
$yellow_color:#ffbb55;
$red_color:#ff2233;

// Link colors
$link_color: $brand_color;
$link_focus_color: invert($link_color);

// Selection
$selection_bg_color: $link_color;
$selection_fg_color: $base_color;

// Borders
$border_color: darken($bg_color, 8%);
$border_radius: 5px;

// Row
$row_width: 744px;

// Shadows
$text_shadow: 0 1px rgba(255,255,255,0.8);
$text_shadow_dark: 0 -1px rgba(0,0,0,0.3);