// Custom Text Classes

// Footnote 
.footnote {
	color:lighten($fg_color, 15%);
	display:block;
	float:right;
	font-family:$monospace;
	font-size:$font_size - 4px;
	margin:8px auto;
	text-align:right;
	width:100%;
}

// Published
.published {
	color:lighten($fg_color, 30%);
	display:block;
	font-family:$monospace;
	margin:16px auto;
	opacity:0.8;
	text-transform:uppercase;
	width:100%;

	.date {
		color:darken($fg_color, 20%);
	}
	.author {
		color:darken($fg_color, 20%);
	}
}

// monospace
.monospace {
	font-family: $monospace;
}

pre > span.comment {
	opacity: 0.5;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none; 
}