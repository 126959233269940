// Navigation

$nav_fg_color: lighten($fg_color, 5%);
$nav_height: 48px;
$nav_icon_size: 16px;

nav {
	padding:0;
	z-index: 10;
	width: 100%;
	background-color:$bg_color;
	height:$nav_height;
	border-bottom: 1px dashed $border_color;

	// bottom nav
	&.bottom {
		border-top: 1px dashed $border_color;
		border-bottom: none;
	}

	// images
	svg {
		fill: $link_color;
		height:$nav_icon_size;
		width:$nav_icon_size;
		padding: 16px 0;
	}

	// text and links
	a,span {
		display: inline-block;
		font-family: $monospace;
		font-size: $font_size;
		font-weight: 300;
		line-height: $nav_height;
		max-height:$nav_height;
		overflow: hidden;
		padding: 0 16px;
		text-decoration: none;
		text-overflow: ellipsis;
		vertical-align: top;
		white-space: nowrap;
	}

	span.prev,span.next{opacity: 0.5;}

	a {
		text-decoration: none;
		@include transition(200ms);

		&:focus,
		&:hover {
			background-color:darken($bg_color, 5%);
			color:$nav_fg_color;
			@include transition(200ms);

			> svg {
				fill: $nav_fg_color;
				@include transition(200ms);
			}
		}
	}

	a.prev,a.next{opacity:1;}

	// search icon
	> svg {
		margin: calc((#{$nav_height} - 24px) * 0.5) 12px;
		margin-right: 0px;
		fill: lighten($nav_fg_color, 20%);
	}

	// search box stylings
	input[type=search]{
		@extend %reset;
		color:$nav_fg_color;
		display: inline-block;
		font-family: $font_family;
		font-size: $font_size + 4px;
		line-height: $font_size + 12px;
		font-weight: 300;
		height: $nav_height * 0.5;
		overflow: hidden;
		padding:calc(#{$nav_height} / 4) 8px;
		padding-right: 12px;
		position: absolute;
		text-overflow: ellipsis;
		vertical-align: baseline;
		white-space: nowrap;
		width: calc(100% - 54px);
		&:focus{
			@extend %reset;
			border: none;
		}
	}

	// just in case
	ul, ol {
		list-style: none;
		list-style-image: none;
	}
}