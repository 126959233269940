.video-wrapper {
	margin:2em auto;
	float:none;
	clear:both;
	width:100%;
	padding-bottom:60%;
	position:relative;
	height:0;

	iframe {
		position:absolute;
		top:0;
		left:0;
		width:100% !important;
		height:100% !important;
	}
}
