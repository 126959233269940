
// Supporters
ul.supporters-list {
	margin: 12px 0;
	padding: 0;
	width:100%;
	text-align:center;
	list-style: none;

	.supporter {
		padding: 4px 0;
		width:calc(50% - 24px);
		display: inline-block;
		background-color:$base_color;
		border:1px solid $border_color;
		border-radius: 4px;
		font-family: $monospace;
		box-shadow: 0 1px 0 0 rgba(255,255,255,0.7);
		font-size: 14px;
		margin: 4px;
		text-align:center;

		&:before{content:""}

		&.active{
			border:1px solid $border_color;
			&:after { 
				content:"♥";
				color:$red_color;
				margin-left: 6px;
			}
		}
		&.former{
			opacity:0.7;
			&:after { 
				content:"♡";
				color:$fg_color;
				margin-left: 6px;
			}
		}
		&.follower{display:none;opacity:0.7;border:1px solid $base_color;}
	}
}

// small
@media only screen and (max-width:600px) {
	ul.supporters-list > li.supporter {width: calc(100% - 24px);}
}