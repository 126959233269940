// Collapse

$dropdown_border_radius: 5px;

.dropdown {
	background-color: #fff;
	border-radius: $dropdown_border_radius;
	border: 1px solid $border_color;
	display: inline-block;
	margin: 12px auto;
	width: 100%;

	> a {
		color:$fg_color;
		display: block;
		font-size: $font_size;
		line-height: 20px;
		margin: 0;
		padding: 8px 12px;
		pointer:hand;
		text-decoration: none;
		width:calc(100% - 24px);

		> i {
			float:right;
			-webkit-transition-duration: 100ms;
			 -o-transition-duration: 100ms;
			transition-duration: 100ms;
		}

		> i.active {
			-webkit-transform: rotate(90deg); 
			-moz-transform: rotate(90deg); 
			-ms-transform: rotate(90deg); 
			-o-transform: rotate(90deg); 
			transform: rotate(90deg);
			-webkit-transition-duration: 100ms;
			-o-transition-duration: 100ms;
			transition-duration: 100ms;
		}
	}
}

%collapse {
	background-color: $base_color;
	border-radius: 0 0 $dropdown_border_radius $dropdown_border_radius;
	margin:0;
	padding:6px;
}

.collapse,
.collapse.in,
.collapsing {
	@extend %collapse;
}

.collapse {
	display:none;
	&.in {display:block;}
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;

	-webkit-transition-timing-function: ease;
	-o-transition-timing-function: ease;
	transition-timing-function: ease;

	-webkit-transition-duration: .35s;
	-o-transition-duration: .35s;
	transition-duration: .35s;

	-webkit-transition-property: height, visibility;
	-o-transition-property: height, visibility;
	transition-property: height, visibility;
}