// Rows
.row {
	display:table;
	position:relative;
	margin:0 auto;
	width:$row_width;
	&.wide {
		width:$row_width + 200px;
	}
}

@media only screen and (max-width:848px) {
	.row{width:90%;margin:0 5%;}
}