// Footer
$footer_height: 96px;
$footer_bg_color: $base_color;
$footer_fg_color: lighten($fg_color, 40%);


footer {
	// background-color:$footer_bg_color;
	padding-top: 32px;
	min-height: $footer_height;
	text-align: center;
	width:100%;
	border-top: 1px solid $border_color;

	a,
	span {
		font-family: $monospace;
		font-size: $font_size - 2px;
	}

	span {
		color: $footer_fg_color;
		display: inline-block;

		&:before {
			content: "•";
			padding:0 1em;
		}

		&:nth-child(1):before {
			content: "";
		}
	}

	a {
		display: inline-block;
		text-decoration: none;

		&:hover {
			color: $footer_fg_color;
		}
	}
}

// MEDIA QUERIES
@media only screen and (max-width:848px) {

	footer {
		span {
			width: 100%;
			text-align: left;
			margin: 12px auto;
			display: block;
			float: left;
			&:before {
				content: "";
				padding:0;
			}
		}
	}
}